import React from 'react';
import CharacterItem from './CharacterItem';

const InitiativeList = ({ characters, removeCharacter, editCharacter }) => {
  return (
    <ul>
      {characters
        .sort((a, b) => b.initiative - a.initiative)
        .map((character, index) => (
          <CharacterItem
            key={index}
            character={character}
            index={index}
            removeCharacter={removeCharacter}
            editCharacter={editCharacter}
          />
        ))}
    </ul>
  );
};

export default InitiativeList;
