import React, { useState, useEffect, useRef } from 'react';

const CharacterItem = ({ character, index, removeCharacter, editCharacter }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(character.name);
  const [initiative, setInitiative] = useState(character.initiative);
  const [currentHealth, setCurrentHealth] = useState(character.currentHealth);
  const [maxHealth, setMaxHealth] = useState(character.maxHealth);
  const [healthChange, setHealthChange] = useState(0);
  const nameRef = useRef(null);
  const initiativeRef = useRef(null);
  const currentHealthRef = useRef(null);
  const maxHealthRef = useRef(null);

  useEffect(() => {
    if (isEditing) {
      nameRef.current.focus();
    }
  }, [isEditing]);

  const handleEdit = () => {
    setName(character.name);
    setInitiative(character.initiative);
    setCurrentHealth(character.currentHealth);
    setMaxHealth(character.maxHealth);
    setIsEditing(true);
  };

  const handleSave = () => {
    editCharacter(index, { 
      name, 
      initiative: parseInt(initiative, 10), 
      currentHealth: parseInt(currentHealth, 10), 
      maxHealth: parseInt(maxHealth, 10) 
    });
    setIsEditing(false);
  };

  const handleBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      handleSave();
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSave();
    }
  };

  const adjustHealth = (e) => {
    e.stopPropagation();
    const newCurrentHealth = currentHealth + parseInt(healthChange, 10);
    setCurrentHealth(newCurrentHealth);
    editCharacter(index, { 
      name, 
      initiative: parseInt(initiative, 10), 
      currentHealth: newCurrentHealth, 
      maxHealth 
    });
    setHealthChange(0);
  };

  return (
    <li
      onClick={handleEdit}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer',
        position: 'relative',
        padding: '10px',
        backgroundColor: '#f0f0f0',
        marginBottom: '5px',
        width: '100%',
        maxWidth: '300px',
      }}
    >
      {isEditing ? (
        <div onBlur={handleBlur} style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            onKeyDown={handleKeyDown}
            ref={nameRef}
            style={{ marginRight: '10px', flex: 1 }}
          />
          <input
            type="number"
            value={initiative}
            onChange={(e) => setInitiative(e.target.value)}
            onKeyDown={handleKeyDown}
            ref={initiativeRef}
            style={{ marginRight: '10px', width: '80px' }}
          />
          <input
            type="number"
            value={currentHealth}
            onChange={(e) => setCurrentHealth(e.target.value)}
            onKeyDown={handleKeyDown}
            ref={currentHealthRef}
            style={{ marginRight: '10px', width: '80px' }}
          />
          <input
            type="number"
            value={maxHealth}
            onChange={(e) => setMaxHealth(e.target.value)}
            onKeyDown={handleKeyDown}
            ref={maxHealthRef}
            style={{ marginRight: '10px', width: '80px' }}
          />
        </div>
      ) : (
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <span style={{ flex: 1 }}>{character.name}: {character.initiative} (HP: {currentHealth} / {maxHealth})</span>
          <div
            onClick={(e) => e.stopPropagation()}
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <input
              type="number"
              value={healthChange}
              onChange={(e) => setHealthChange(e.target.value)}
              style={{ width: '50px', marginRight: '5px' }}
            />
            <button onClick={adjustHealth} style={{ marginRight: '5px' }}>Update Health</button>
          </div>
          <button
            onClick={(e) => {
              e.stopPropagation();
              removeCharacter(index);
            }}
            className="remove-btn"
            style={{ 
              backgroundColor: 'transparent', 
              color: '#e74c3c', 
              border: 'none', 
              cursor: 'pointer', 
              padding: '5px', 
              fontSize: '18px',
              top: '50%',
              transform: 'translateY(-50%)'
            }}
          >
            &#x2715;
          </button>
        </div>
      )}
    </li>
  );
};

export default CharacterItem;
