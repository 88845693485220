import React, { useState } from 'react';

const CharacterForm = ({ addCharacter }) => {
  const [name, setName] = useState('');
  const [initiative, setInitiative] = useState('');
  const [maxHealth, setMaxHealth] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const maxHealthValue = parseInt(maxHealth, 10);
    addCharacter({ 
      name, 
      initiative: parseInt(initiative, 10), 
      currentHealth: maxHealthValue, 
      maxHealth: maxHealthValue 
    });
    setName('');
    setInitiative('');
    setMaxHealth('');
  };

  return (
    <form onSubmit={handleSubmit}>
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Character Name"
        required
      />
      <input
        type="number"
        value={initiative}
        onChange={(e) => setInitiative(e.target.value)}
        placeholder="Initiative"
        required
      />
      <input
        type="number"
        value={maxHealth}
        onChange={(e) => setMaxHealth(e.target.value)}
        placeholder="Max Health"
        required
      />
      <button type="submit">Add Character</button>
    </form>
  );
};

export default CharacterForm;
