import React, { useState } from 'react';
import CharacterForm from './CharacterForm';
import InitiativeList from './InitiativeList';

const App = () => {
  const [characters, setCharacters] = useState([]);

  const addCharacter = (character) => {
    setCharacters([...characters, character]);
  };

  const removeCharacter = (index) => {
    const newCharacters = characters.filter((_, i) => i !== index);
    setCharacters(newCharacters);
  };

  const editCharacter = (index, updatedCharacter) => {
    const newCharacters = characters.map((character, i) =>
      i === index ? updatedCharacter : character
    );
    setCharacters(newCharacters);
  };

  return (
    <div>
      <h1>D&D Initiative Tracker</h1>
      <CharacterForm addCharacter={addCharacter} />
      <InitiativeList characters={characters} removeCharacter={removeCharacter} editCharacter={editCharacter} />
    </div>
  );
};

export default App;
